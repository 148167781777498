<template>
    <b-collapse :open="open" animation="slide" aria-id="contentIdForA11y3">
        <template #trigger="props">
            <h3
                class="detail-header"
            >
                {{title}}
                <b-icon
                    class="is-pulled-right"
                    :icon="props.open ? 'menu-up' : 'menu-down'">
                </b-icon>
            </h3>
        </template>
        <div class="detail-content">
            <Marked :input="content" />
        </div>
    </b-collapse>
</template>

<script>
import Marked from '@/components/Marked.vue';

export default {
    name: 'RouteDetailSection',
    components: {
        Marked,
    },
    props: {
        title: String,
        content: String,
    },
    data() {
        return {
            open: false,
        };
    },
    mounted() {
        // set "open" property based on screen size
        const tablet_size = 768;
        this.open = window.innerWidth > tablet_size;
    },
};
</script>

<style lang="scss" scoped>
    .detail-header {
        font-size: 25px;
        font-weight: $font-bold;
        line-height: 38px;
        border-bottom: thin solid #E2E2E2;
    }
    .detail-content {
        padding: 0.5em 0;
        font-size: 14px;
    }
</style>
