<template>
    <div class="route-path-line" :class="{ 'black': black }">
        <b-icon
            icon="close"
            size="is-small"
        >
        </b-icon>
        <div class="dashed"></div>
        <b-icon
            icon="close"
            size="is-small">
        </b-icon>
    </div>
</template>
<script>
export default {
    name: 'RoutePathLine',
    props: {
        black: Boolean,
    },
};
</script>

<style scoped lang="scss">
    .route-path-line {
        color: $white;

        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        .dashed {
            height: 1em;
            margin-right: 7px;
            border-left: 2px dashed $white;
            flex: 1;
        }

        &.black {
            color: $black;
            .dashed {
                border-color: $black;
            }
        }
    }
</style>
