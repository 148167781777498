var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"route-image"},[_c('p',{staticClass:"route-image-bubble carbon-saving"},[_c('span',[_vm._v(_vm._s(_vm.route.detail.carbonsaving)+"% CO"),_c('sub',[_vm._v("2")]),_vm._v(" saved")])]),_c('p',{staticClass:"route-image-bubble favourite",class:{
            'favourite-hover' : _vm.favourite_hover,
        },on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavourite({
            route_id: _vm.route.detail.id,
            is_favourite: !_vm.route.detail.is_favourite,
        })},"mouseover":function($event){_vm.favourite_hover = true},"mouseleave":function($event){_vm.favourite_hover = false}}},[_c('b-icon',{staticClass:"mr-1 btn-favourite",class:{
                'has-text-primary' : _vm.route.detail.is_favourite,
            },attrs:{"icon":_vm.route.detail.is_favourite ? 'heart' : 'heart-outline',"size":"is-small"}}),_c('span',[_vm._v("Favourite this route")])],1),_c('img',{attrs:{"src":_vm.background}})])}
var staticRenderFns = []

export { render, staticRenderFns }