<template>
    <div class="content leg-details">
        <div class="days" @click="show_modal = true">
            <RouteDay
                v-for="(day, index) in days"
                :key="`route_day_${index}`"
                :day="day"
                :index=index
                :final="index === days.length - 1"
                :expand="expand"
            />
        </div>
    </div>
</template>

<script>
import RouteDay from '@/components/RouteDay.vue';

export default {
    name: 'RouteLegs',
    props: {
        days: Array,
        expand: Boolean,
    },
    components: {
        RouteDay,
    },
    data() {
        return {
        };
    },
};
</script>

<style scoped lang="scss">
</style>
