<template>
    <div class="container p-2">
        <h3
            class="title full-itinerary-title pb-2 mb-2"
            @click="show_full_itinerary = !show_full_itinerary"
        >
            Full Itinerary
            <button
                class="button is-primary has-text-white full-itinerary-close is-pulled-right"
            >
                {{show_full_itinerary ? 'Close' : 'Open'}}
            </button>
        </h3>
        <div
            class="full-itinerary-content"
            v-if="show_full_itinerary"
        >
            <Marked :input="detail.itinerary" />
            <div class="columns">
                <div class="column">
                    <RouteLegs :days="days" ref="routeLegs" :expand="true" />
                </div>
                <div class="column book-your-tickets-wrapper">
                    <div class="book-your-tickets">
                        <div
                            class="ticket-booking mb-5"
                            v-for="link in booking_links"
                            :key="`link${link.id}`"
                        >
                            <div class="ticket-booking-details">
                                <strong>{{link.title}}</strong>
                                <template v-if="link.subtitle">
                                    <br />
                                    {{link.subtitle}}
                                </template>
                            </div>
                            <a
                                :href="link.href"
                                class="button is-primary has-text-white ticket-booking-link"
                                target="_blank"
                                @click="trackBookingLink(link)"
                            >
                                Book your tickets
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    // mapState,
    // mapGetters,
    mapMutations,
    // mapActions,
} from 'vuex';

import RouteLegs from '@/components/RouteLegs.vue';
import Marked from '@/components/Marked.vue';

export default {
    name: 'RouteFullItinerary',
    components: {
        RouteLegs,
        Marked,
    },
    props: {
        legs: Array,
        days: Array,
        links: Array,
        detail: Object,
    },
    computed: {
        show_full_itinerary: {
            get() {
                return this.$store.state.route.show_full_itinerary;
            },
            set(val) {
                this.setShowFullItinerary(val);
            },
        },
        // return legs with booking links
        booking_links() {
            return this.links.filter((link) => link.linktype.match(/booking_link/i));
        },
    },
    methods: {
        ...mapMutations('route', [
            'setShowFullItinerary',
        ]),
        trackBookingLink(link) {
            this.$mixpanel.track(
                'Booking Link Click',
                {
                    route_id: this.detail.id,
                    route_name: this.detail.name,
                    link_title: link.title,
                    link_href: link.href,
                    link_id: link.id,
                },
            );
        },
    },
};
</script>

<style lang="scss" scoped>
    .full-intinerary-title {
        border-bottom: 3px dashed $grey;
        cursor: pointer;
        text-align: center;

        .full-itinerary-close {
            line-height: 2.85rem;
        }
    }

    .book-your-tickets {
        @include clip-down-left;
        background-color: $skyBlue;
        padding: 3em 1em;

        .ticket-booking {
            display: flex;
            justify-content: space-between;

            .gr-icon {
                flex: 75px 0 0;
            }
        }
    }

    @media screen and (max-width: $desktop) {
        .full-intinerary-title {
            font-size: 25px;
            line-height: 38px;
            text-align: left;
        }

        .book-your-tickets-wrapper {
            padding-top: 4rem;

            .ticket-booking {
                flex-wrap: wrap;

                .ticket-booking-link {
                    width: 100%;
                }
            }
        }
    }
</style>
