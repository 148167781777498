<template>
    <div class="route-detail-grid">
        <div
            class="route-map mb-2"
            :class="{ 'collapse-map': collapse_map }"
        >
            <img
                :src="routeMap"
                @click="collapse_map = !collapse_map"
            >
            <span
                class="map_toggle"
                @click="collapse_map = !collapse_map"
            >{{collapse_map ? 'View' : 'Collapse'}} map</span>
        </div>
        <div class="columns is-mobile">
            <div class="column has-text-weight-bold">Longest travel day</div>
            <div class="column has-text-left">
                <MinutesDisplay :minutes="detail.average_duration_minutes" />
            </div>
        </div>
        <div class="columns is-mobile">
            <div class="column has-text-weight-bold">Carbon saving:</div>
            <div class="column has-text-left">
                {{detail.carbonsaving}}%
            </div>
        </div>
        <div class="columns is-mobile user-rating">
            <div class="column has-text-weight-bold">User Rating:</div>
            <div
                class="column has-text-left"
            >
                <RatingStars
                    :detail="detail"
                    :floated="false"
                    rating_type="avg_rating"
                />
            </div>
        </div>
        <div class="columns is-mobile">
            <div class="column has-text-weight-bold">Our Calculated Price</div>
            <div class="column has-text-left">
                &pound;{{detail.prices_from}}
            </div>
        </div>
        <div class="has-text-centered">
            <b-button
                class="is-primary has-text-white btn-book"
                @click="scrollToBookingLinks()"
            >
                See full itinerary
            </b-button>
        </div>
    </div>
</template>
<script>
import {
    mapMutations,
} from 'vuex';

import MinutesDisplay from '@/components/MinutesDisplay.vue';
import RatingStars from '@/components/RatingStars.vue';

export default {
    name: 'RouteDetailGrid',
    components: {
        MinutesDisplay,
        RatingStars,
    },
    props: {
        detail: Object,
        pins: Array,
    },
    data() {
        return {
            collapse_map: true,
        };
    },
    computed: {
        routeMap() {
            // build country string
            const countries = this.pins.map((stop) => {
                const first_letter = stop.substr(0, 1);
                return `&markers=color:green|label:${first_letter}|${stop}`;
            }).join('');
            return [
                'https://maps.googleapis.com/maps/api/staticmap',
                '?size=600x600',
                '&maptype=roadmap',
                countries,
                `&path=color:red|weight:5|${this.pins.join('|')}`,
                '&key=AIzaSyAXNN84W7z4WerBnDFgZY1JC4rl_KI6jpM',
                '&style=feature:road|visibility:off',
                '&style=feature:administrative.country|element:labels|visibility:off',
                '&style=feature:administrative.country|element:geometry|color:0x666666',
                '&style=feature:poi|visibility:off&style=feature:water|visibility:simplified',
                '&style=feature:landscape|color:0xEBE9D7',
            ].join('');
        },
        show_full_itinerary: {
            get() {
                return this.$store.state.route.show_full_itinerary;
            },
            set(val) {
                this.setShowFullItinerary(val);
            },
        },
    },
    methods: {
        ...mapMutations('route', [
            'setShowFullItinerary',
        ]),
        scrollToBookingLinks() {
            const app = document.getElementById('the-full-itinerary');
            this.$smoothScroll({
                scrollTo: app,
                hash: '#search',
            });
            this.show_full_itinerary = true;
        },
    },
};
</script>

<style lang="scss" scoped>
    .route-detail-grid {
        background-color: $skyBlue;
        padding: 1em;

        .columns {
            margin: 0 2em;

            .column {
                font-size: 12px;
                padding: 0.25em;
            }
        }

        .btn-book {
            margin: 1em auto;
        }

        .route-map {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            flex-direction: column;

            .map_toggle {
                text-decoration: underline;
            }

            &.collapse-map {
                flex-direction: row;
                img {
                    width: 75px;
                }
            }
        }
    }

    @media screen and (min-width: $desktop) {
        .route-detail-grid {
            padding: 0;
            .route-map {
                flex-direction: column;
                .map_toggle {
                    display: none;
                }

                &.collapse-map {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
</style>
