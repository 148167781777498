<template>
    <div class="route-image">
        <p
            class="route-image-bubble carbon-saving"
        >
            <span>{{route.detail.carbonsaving}}% CO<sub>2</sub> saved</span>
        </p>
        <p
            class="route-image-bubble favourite"
            :class="{
                'favourite-hover' : favourite_hover,
            }"
            @click.stop="toggleFavourite({
                route_id: route.detail.id,
                is_favourite: !route.detail.is_favourite,
            })"
            @mouseover="favourite_hover = true"
            @mouseleave="favourite_hover = false"
        >
            <b-icon
                :icon="route.detail.is_favourite ? 'heart' : 'heart-outline'"
                class="mr-1 btn-favourite"
                size="is-small"
                :class="{
                    'has-text-primary' : route.detail.is_favourite,
                }"
            ></b-icon>
            <span>Favourite this route</span>
        </p>
        <img :src="background" />
    </div>
</template>
<script>
import {
    mapActions,
    mapState,
} from 'vuex';

export default {
    name: 'RouteImage',
    data() {
        return {
            favourite_hover: false,
        };
    },
    computed: {
        ...mapState({
            route: (state) => state.route.route_detail,
        }),
        has_legs() {
            // check if legs are present, if not the route will be loading
            return typeof this.route.legs !== 'undefined';
        },
        background() {
            // set default image
            let route_image = '/image/logo.png/';
            // if we have legs, we have a route
            if (this.has_legs && this.route.detail.image) {
                // get the image
                route_image = this.route.detail.image;
                const cloudinary_url_pattern = '^https://res.cloudinary.com/.*/image/upload/';
                const cloudinary_url_re = new RegExp(cloudinary_url_pattern, 'gi');
                const cloudinary_transforms_pattern = 'w_auto:100:900,dpr_auto,q_auto,f_auto';
                const cloudinary_transforms_re = new RegExp(cloudinary_transforms_pattern, 'gi');

                /**
                 * if it is a cloudinary image, make sure it has the correct overrides
                 */
                if (
                    route_image.match(cloudinary_url_re)
                    && !route_image.match(cloudinary_transforms_re)
                ) {
                    route_image = route_image.replace(/(upload\/)(v[0-9]{10})/, `$1${cloudinary_transforms_pattern}/$2`);
                }
            }
            return route_image;
        },
    },
    methods: {
        ...mapActions('route', [
            'toggleFavourite',
        ]),
    },
};
</script>

<style scoped lang="scss">
    .route-image {
        position: relative;
        background-color: $skyBlue;

        img {
            width: 100%;
        }
    }
    .route-image-bubble {
        background-color: $skyBlue;
        border-radius: 2em;
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
        padding: 0 0.5rem;

        &.carbon-saving {
            right: 0.5rem;
            left: auto;
        }

        span {
            font-size: 11px;
            font-weight: $font-medium;
        }

        sub {
            font-size: 8px;
            font-weight: $font-medium;
            vertical-align: baseline;
        }
    }

    .favourite {
        cursor: pointer;
        &.favourite-hover {
            background-color: lighten($primary,20%);
        }
    }

    @media screen and (min-width: $desktop) {
        .route-image {
            background: transparent;
        }
    }
</style>
