<template>
    <section class="mt-4 travel-info">
        <div class="container">
            <div class="columns has-text-centered pl-4 pr-4">
                <div
                    class="column"
                    v-for="prop in info_props"
                    :key="`route_info${prop}`"
                >
                    <span
                        class="gr-icon gr-icon-small"
                        :class="`gr-icon-${prop.replace(/_/g, '')}`"
                    ></span>
                    <h4 class="title is-6 is-capitalized">
                        {{prop.replace(/_/g, ' ')}}
                    </h4>
                    <Marked
                        :input="detail[prop] ? detail[prop] : '-'"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Marked from '@/components/Marked.vue';

export default {
    name: 'RouteTravelInfo',
    props: {
        detail: Object,
    },
    components: {
        Marked,
    },
    data() {
        return {
            info_props: ['check_in_timings', 'interchange_information', 'onboard_facilities'],
        };
    },
};
</script>

<style lang="scss" scoped>
    .travel-info {
        background-color: $pink;
        @include clip-down-left;
        padding: 3em 0;
    }
</style>
