<template>
    <div :class="{ 'pb-6' : !route.links.length }" class="mt-4">
        <section class="route-title has-text-centered" v-if="!is_loading">
            <h1 class="title mb-1">
                {{ route.detail.name }}
                <span class="subtitle">
                    {{ route.detail.subtitle }}
                </span>
            </h1>
            <template v-if="has_legs">
                <p class="has-text-centered stopover-label">
                    {{route_locations.join(' - ')}}
                </p>
            </template>
        </section>

        <section class="" v-if="!is_loading">
            <div class="container mt-1">
                <div class="route-actions">
                    <a class="back-button is-flex" @click="$router.go(-1)">
                        <b-icon
                            icon="menu-left"
                            size="">
                        </b-icon>
                        Back to search results
                    </a>
                </div>
            </div>
        </section>

        <template v-if="has_legs">
            <section class="">
                <div class="container p-1">
                    <div class="columns is-gapless is-mobile route-description-and-grid">
                        <div class="column is-two-thirds-desktop">
                            <div class="route-description">
                                <RouteImage class="is-hidden-touch" />
                                <Marked :input="route.detail.description" class="mt-4 " />

                                <RouteDetailSection
                                    title="Tell us more!"
                                    :content="route.detail.tell_me_more"
                                />
                                <RouteDetailSection
                                    title="When should I go?"
                                    :content="route.detail.when_to_travel"
                                />
                            </div>
                        </div>
                        <div class="column">
                            <RouteImage class="is-hidden-desktop" />
                            <RouteDetailGrid
                                :detail="route.detail"
                                :pins="pins"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <div class="the-stops-wrapper">
                <section class="mt-4 the-stops">
                    <div class="container p-4 pt-6">
                        <div class="columns is-mobile the-stops-content">
                            <div class="column the-stops-content-inner">
                                <RouteLegs :days="days" ref="routeLegs" :expand="false" />
                            </div>
                            <div class="column">
                                <div class="route-image" :style="background"></div>
                            </div>
                        </div>
                        <div class="content has-text-centered">
                            <button
                                class="button is-primary btn-book-route has-text-white"
                                @click="scrollToBookingLinks()"
                                target="_blank"
                            >
                                See full itinerary
                            </button>
                        </div>
                    </div>
                </section>
                <div class="the-stops-title-wrapper">
                    <div class="container">
                        <div class="the-stops-title">
                            The Stops
                        </div>
                    </div>
                </div>
            </div>

            <section class="mt-4 p-4 the-full-itinerary" id="the-full-itinerary">
                <RouteFullItinerary
                    :detail="route.detail"
                    :links="route.links"
                    :legs="route.legs"
                    :days="days"
                />
            </section>

            <RouteTravelInfo :detail="route.detail" class="mt-4" />

            <div
                class="content has-text-centered pl-4 pr-4 mt-6 mb-0"
                v-if="route.detail.travel_tip"
            >
                <span
                    class="gr-icon gr-icon-small gr-icon-goodtoknow"
                ></span>
                <Marked :input="route.detail.travel_tip" />
            </div>

            <HomeLinks
                v-if="route.links.length && !is_loading"
                title="Other good routes"
                :titlecentered=true
                :links="other_links"
            />
        </template>
    </div>
</template>

<script>
import {
    mapState,
    mapGetters,
    mapMutations,
    mapActions,
} from 'vuex';

import RouteFullItinerary from '@/components/RouteFullItinerary.vue';
import RouteDetailSection from '@/components/RouteDetailSection.vue';
import RouteImage from '@/components/RouteImage.vue';
import RouteDetailGrid from '@/components/RouteDetailGrid.vue';
import RouteTravelInfo from '@/components/RouteTravelInfo.vue';
import RouteLegs from '@/components/RouteLegs.vue';
import HomeLinks from '@/components/HomeLinks.vue';
import Marked from '@/components/Marked.vue';

import router from '../router';

export default {
    name: 'Route',
    components: {
        RouteFullItinerary,
        RouteDetailSection,
        RouteImage,
        RouteDetailGrid,
        RouteTravelInfo,
        RouteLegs,
        HomeLinks,
        Marked,
    },
    data() {
        return {
            info_props: ['check_in_timings', 'interchange_information', 'onboard_facilities'],
        };
    },
    async mounted() {
        // check logged in
        if (!this.is_logged_in) {
            router.push({
                path: '/home',
                query: {
                    redirect: this.$route.path,
                },
            });
        } else {
            // load user data
            await this.getUserData();

            if (!this.is_verified) {
                router.push({
                    path: '/account',
                });
            } else {
                // otherwise we can load the route data
                this.fetchSingleRoute(this.$route.params.id);
            }
        }
    },
    computed: {
        ...mapState({
            is_loading: (state) => state.route.is_loading,
            route: (state) => state.route.route_detail,
            routes: (state) => state.route.arr_routes,
            is_logged_in: (state) => state.user.is_logged_in,
        }),
        ...mapGetters('user', [
            'is_verified',
        ]),
        show_full_itinerary: {
            get() {
                return this.$store.state.route.show_full_itinerary;
            },
            set(val) {
                this.setShowFullItinerary(val);
            },
        },
        has_legs() {
            // check if legs are present, if not the route will be loading
            return typeof this.route.legs !== 'undefined';
        },
        days() {
            const arr_days = [];
            // build a new day each time we hit a leg with stopovers
            // list all other legs as "transfers"

            // loop over legs
            let current_day = -1;
            let total_days = 1;
            this.route.legs.forEach((leg, idx_leg) => {
                // get the prev leg if we can
                const prev_leg = idx_leg > 0 ? this.route.legs[idx_leg - 1] : {};
                // first leg or if leg before had stopover_days/is_overnight, this is a new day
                if (idx_leg === 0 || prev_leg.stopover_days || prev_leg.is_overnight) {
                    // increase total days if we're starting a new day
                    // also update the previous days stopover days
                    if (
                        typeof prev_leg.stopover_days !== 'undefined'
                            || typeof prev_leg.is_overnight !== 'undefined'
                    ) {
                        // update the current day
                        total_days += prev_leg.stopover_days + prev_leg.is_overnight;
                    }
                    // build the new day object
                    const new_day = {
                        legs: [leg],
                        number: total_days,
                    };
                    arr_days.push(new_day);
                    current_day += 1;
                } else {
                    arr_days[current_day].legs.push(leg);
                }
            });

            return arr_days;
        },
        route_locations() {
            // get the destination of legs with stopover days
            const arr_stopovers = this.route.legs
                .filter((leg) => leg.stopover_days > 0)
                .map((leg) => `${leg.destination_location}`);
            // return the start destination followed by the stopovers
            return [
                this.route.legs[0].departure_location,
                ...arr_stopovers,
            ];
        },
        stopovers() {
            // get the destination of legs with stopover days
            return this.route.legs
                .filter((leg) => leg.stopover_days > 0)
                .map((leg) => `${leg.destination_location}, ${leg.destination_country}`);
        },
        pins() {
            // return the departure location of the first leg, followed by any stopovers
            const departure_leg = this.route.legs[0];
            const departure_location = `${departure_leg.departure_location},${departure_leg.departure_country}`;
            return this.stopovers.concat([departure_location]);
        },
        background() {
            // set default image
            let route_image = 'background: no-repeat center/contain url(/image/logo.png/);';
            // if we have legs, we have a route
            if (this.has_legs && this.route.detail.image) {
                // get the image
                route_image = this.route.detail.image;
                const cloudinary_url_pattern = '^https://res.cloudinary.com/.*/image/upload/';
                const cloudinary_url_re = new RegExp(cloudinary_url_pattern, 'gi');
                const cloudinary_transforms_pattern = 'w_auto:100:900,dpr_auto,q_auto,f_auto';
                const cloudinary_transforms_re = new RegExp(cloudinary_transforms_pattern, 'gi');

                /**
                 * if it is a cloudinary image, make sure it has the correct overrides
                 */
                if (
                    route_image.match(cloudinary_url_re)
                    && !route_image.match(cloudinary_transforms_re)
                ) {
                    route_image = route_image.replace(/(upload\/)(v[0-9]{10})/, `$1${cloudinary_transforms_pattern}/$2`);
                }
                return `background: no-repeat center top/cover url(${this.route.detail.image});`;
            }
            return route_image;
        },
        other_links() {
            return this.route.links.filter((link) => !link.linktype.match(/booking_link/i));
        },
    },
    methods: {
        ...mapMutations('route', [
            'setShowFullItinerary',
        ]),
        ...mapActions('route', [
            'fetchSingleRoute',
        ]),
        ...mapActions('user', [
            'getUserData',
        ]),
        scrollToBookingLinks() {
            this.$mixpanel.track(
                'Scroll To Booking Links',
                {
                    id: this.route.id,
                    name: this.route.name,
                },
            );
            const app = document.getElementById('the-full-itinerary');
            this.$smoothScroll({
                scrollTo: app,
                hash: '#search',
            });
            this.show_full_itinerary = true;
        },
    },
};
</script>

<style lang="scss" scoped>
    .route-title {
        .title,
        .subtitle {
            font-size: 21px;
            line-height: 28px;
        }
        .subtitle {
            display: block;
        }
    }

    .route-actions {
        display: flex;
        justify-content: space-between;
    }

    .stopover-label {
        background-color: $pink;
        font-size: 23px;
        line-height: 31px;
        font-weight: $font-semi-bold;
        display: inline-block;
        padding: 0.25em 0.5em;
        border-radius: 2em;
    }

    .back-button {
        display: inline;
    }

    .route-description-and-grid {
        flex-direction: column-reverse;
    }

    .route-image {
        width: 100%;
    }

    .route-map {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .the-stops-wrapper {
        position: relative;
        .the-stops-title-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            .the-stops-title {
                padding: 17px 17px 16px 20px;
                border-radius: 34px;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                background-color: #ffffff;
                font-size: 25px;
                line-height: 38px;
                font-weight: $font-bold;
                float: left;
                margin-top: -0.5em;
                margin-left: 0.5em;
            }
        }
        .the-stops {
            @include clip-down-left;
            background-color: $skyBlue;
            padding: 3em 0;

            .the-stops-content {
                flex-direction: column-reverse;

                .the-stops-content-inner {
                    max-width: 400px;
                }
            }
        }
    }

    @media screen and (min-width: $desktop) {
        .route-title {
            .title,
            .subtitle {
                font-size: 30px;
                line-height: 38px;
            }
            .title {
                font-weight: $font-bold;
            }
            .subtitle {
                font-weight: $font-semi-bold;
                display: inline;
            }
        }

        .route-description-and-grid {
            flex-direction: row;
        }

        .route-description {
            padding-right: 0.5rem;
        }

        .the-stops-wrapper {
            .the-stops {
                .the-stops-content {
                    flex-direction: row;

                    .the-stops-content-inner {
                        max-width: none;
                    }
                }
            }
        }
    }
</style>
