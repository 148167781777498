<template>
    <div
        class="day"
        :class="{
            'day-expanded' : expand,
            'day-summary' : !expand,
            'mb-5' : !final,
        }"
    >
        <h4 class="title day-title is-4 mb-1 script-font">Day {{day.number}}</h4>

        <p v-if="expand && itinerary != ''">
            {{itinerary}}
        </p>

        <div class="start-stop">
            <div class="mb-0 start-stop-legs" v-if="expand">
                <div
                    class="mt-1 mb-1"
                    v-for="(leg, leg_index) in day.legs"
                    :key="`leg${leg_index}`"
                >
                    <div class="columns is-mobile">
                        <div class="column route-line-path-wrapper mt-2 mb-4">
                            <RoutePathLine :black="true" />
                        </div>
                        <div class="column">
                            <!-- departure -->
                            <div class="columns is-mobile start-stop-leg-info">
                                <div class="column travel-time has-text-primary has-text-right">
                                    {{leg.departure_time}}
                                </div>
                                <div class="column is-three-quarters">
                                    {{leg.departure_station}}
                                    <template v-if="expand && leg.frequency">
                                        <span class="is-italic"> - {{leg.frequency}}</span>
                                    </template>
                                </div>
                            </div>

                            <!-- travel type -->
                            <div class="columns is-mobile start-stop-leg-info">
                                <div
                                    class="column travel-icon"
                                >
                                    <span
                                        class="gr-icon gr-icon-tiny"
                                        :class="[
                                            `gr-icon-${traveltypeToClass(leg.traveltype_name)}`,
                                        ]"
                                    ></span>
                                </div>
                                <div class="column is-three-quarters">
                                    {{leg.operator_name}}
                                    <br />
                                    <MinutesDisplay :minutes="leg.duration_minutes" />
                                </div>
                            </div>

                            <!-- destination -->
                            <div class="columns is-mobile start-stop-leg-info">
                                <div class="column travel-time has-text-primary has-text-right">
                                    {{leg.arrival_time}}
                                </div>
                                <div class="column is-three-quarters">
                                    {{leg.destination_station}}
                                    <template v-if="expand && leg.frequency">
                                        <span class="is-italic"> - {{leg.frequency}}</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="start-stop-summary columns is-mobile" v-else>
                <div class="column route-line-path-wrapper">
                    <RoutePathLine :black="false" />
                </div>
                <div class="column">
                    <div class="mb-2">
                        <span v-if="expand" class="has-text-primary">
                            {{departure.departure_time}}
                        </span>
                        {{departure.departure_station}}
                    </div>
                    <div class="">
                        <span v-if="expand" class="has-text-primary">
                            {{destination.arrival_time}}
                        </span>
                        {{destination.destination_station}}
                    </div>
                </div>
                <!-- <span class="is-italic" v-if="expand && departure.frequency">
                    - {{departure.frequency}}
                </span> -->
            </div>

            <div
                class="stopover-info has-background-white"
                :class="{ 'expanded-stopover-info' : expand }"
                v-if="overnight_display"
            >
                <span
                    class="gr-icon gr-icon-tiny gr-icon-moon"
                    v-if="expand"
                ></span>
                {{overnight_display}}
            </div>
        </div>

    </div>
</template>
<script>
import MinutesDisplay from '@/components/MinutesDisplay.vue';
import RoutePathLine from '@/components/RoutePathLine.vue';

export default {
    name: 'RouteDay',
    props: {
        day: Object,
        index: Number,
        final: Boolean,
        expand: Boolean,
    },
    components: {
        MinutesDisplay,
        RoutePathLine,
    },
    computed: {
        overnight_display() {
            if (this.stopover_days) {
                const plural = this.stopover_days > 1 ? 's' : '';
                return `Stay ${this.stopover_days} night${plural} in ${this.destination.destination_location}`;
            }
            if (this.is_overnight) {
                // if it's overnight, it's the final leg of the day, get that legs travel type
                const overnight_type = [...this.day.legs].pop().traveltype_name;
                const overnight_phrase = overnight_type.match(/ferry/i) ? 'at sea' : ' travelling';
                const plural = this.is_overnight > 1 ? 's' : '';
                return `${this.is_overnight} night${plural} ${overnight_phrase}`;
            }
            //  default fallback to blank
            return '';
        },
        stopover_days() {
            return this.destination.stopover_days;
        },
        is_overnight() {
            return this.destination.is_overnight;
        },
        departure() {
            return this.day.legs[0];
        },
        destination() {
            return this.day.legs[this.day.legs.length - 1];
        },
        itinerary() {
            return this.day.legs.map((leg) => leg.itinerary).join('');
        },
    },
    methods: {
        traveltypeToClass(traveltype) {
            return traveltype.replace(/ /, '-').toLowerCase();
        },
    },
};
</script>

<style scoped lang="scss">
    .day {
        display: flex;
        flex-direction: column;

        .day-title {
            font-size: 41px;
            line-height: 56px;
            flex: 0.5;
        }

        .start-stop {
            flex: 1;

            .start-stop-summary {
                margin-bottom: 0;
            }

            .start-stop-leg-info {
                margin-bottom: 0;

                .travel-icon,
                .travel-time {
                    flex: 0 0 4em;
                }

                .travel-icon {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
        }
    }

    .route-line-path-wrapper {
        flex: 0 0 16px;
        &.mb-4 {
            margin-bottom: 1.25rem !important;
        }
    }

    .stopover-info {
        display: inline-block;
        font-size: 16px;
        font-weight: $font-bold;
        line-height: 38px;
        padding: 0 1em;
        border-radius: 2em;
        color: $primary;

        &.expanded-stopover-info {
            color: $black;
            padding: 0;
        }
    }

    @media screen and (min-width: $tablet) {
        .day {
            &.day-summary {
                flex-direction: row;
            }
        }
    }
</style>
