import { render, staticRenderFns } from "./RouteLegs.vue?vue&type=template&id=17f6bbaa&scoped=true&"
import script from "./RouteLegs.vue?vue&type=script&lang=js&"
export * from "./RouteLegs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17f6bbaa",
  null
  
)

export default component.exports