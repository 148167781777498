<template>
    <span class="minutes-display">
        {{minutes_display}}
    </span>
</template>
<script>
export default {
    name: 'MinutesDisplay',
    props: {
        minutes: Number,
    },
    computed: {
        minutes_display() {
            // turn into hours and minutes
            const total_minutes = this.minutes;
            const total_hours = Math.floor(total_minutes / 60);
            const remaining_minutes = total_minutes % 60;
            return `${total_hours}h ${remaining_minutes}m`;
        },
    },
};
</script>

<style scoped>
    .minutes-display {
        font-size: 12px;
    }
</style>
